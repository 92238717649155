<template>

  <v-bottom-navigation
    id="bottom-navigation"
    v-model="bnav"
    absolute
    active-class=""
    class="position-absolute position-bottom pt-3"
    color="primary"
    grow
    mode="shift"
  >
    <v-btn  small
      text
      value="home"
      to="/home"
    >
      <span class="">Nouveaute</span>

      <v-icon
        :size="icons_size"
        class=""
      >
        {{ icons.mdiHomeOutline }}
      </v-icon>
    </v-btn>

    <v-btn
      text small
      to="/social"
    >
      <span class="">Actualite</span>

      <v-icon
        :size="icons_size">
        {{ icons.mdiDraw }}
      </v-icon>
    </v-btn>

    <v-btn small
        text
      to="/creator"
    >
      <span class="">Createur</span>

      <v-icon
        :size="icons_size">
        {{ icons.mdiPlus }}
      </v-icon>
    </v-btn>

    <v-btn
      text small
      to="/catalog"
    >
      <span class="">Catalogue</span>

      <v-icon
        :size="icons_size">
        {{ icons.mdiBookOpenPageVariantOutline }}
      </v-icon>
    </v-btn>

    <v-btn
      color="deep-purple accent-4"
      text small
      :to="user.id ? '/profile/'+user.id : '/auth/login'"
    >
      <span class="">Mon compte</span>

      <v-icon
        :size="icons_size">
        {{ icons.mdiAccountOutline }}
      </v-icon>
    </v-btn>
  </v-bottom-navigation>

</template>

<script>
import {
  mdiAccessPoint,
  mdiBellOutline,
  mdiAccountGroupOutline,
  mdiAccountOutline,
  mdiCogOutline, mdiCreation, mdiEyeOffOutline,
  mdiEyeOutline, mdiGamepadCircleOutline,
  mdiPlus,
  mdiDraw, mdiGrid, mdiHomeOutline, mdiAlignVerticalBottom, mdiBookOpenPageVariantOutline,

} from '@mdi/js'
import { Drequest } from '@/plugins/Drequest'

export default {
  name: 'BottomNavigation',
  data: () => ({
    bnav: 'home',
    icons_size: 30,
    icons: {
      mdiBellOutline,
      mdiAccountOutline,
      mdiEyeOutline,
      mdiCogOutline,
      mdiCreation,
      mdiGrid,
      mdiHomeOutline,
      mdiAccountGroupOutline,
      mdiAccessPoint,
      mdiDraw,
      mdiPlus,
      mdiGamepadCircleOutline,
      mdiEyeOffOutline, mdiAlignVerticalBottom, mdiBookOpenPageVariantOutline,
    },
    height: window.innerHeight,
    user: {},
  }),
  mounted() {
    this.user = Drequest.getUser()
    if (this.user.id) {
      this.init()
    }
  },
  methods: {
    init() {
    }
  }

}
</script>

<style scoped>

</style>
