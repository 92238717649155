<template>
  <v-app>

    <p-w-a-prompt></p-w-a-prompt>

    <vertical-nav-menu
      :user="user"
      :user_id="user.id"
      :is-drawer-open.sync="isDrawerOpen"
    ></vertical-nav-menu>
    <v-layout>
      <v-app-bar
        app
        color="white"
        density="compact"
        elevation="0"
      >
        <div class="boxed-container w-full">
          <div class="d-flex align-center mx-lg-6">
            <!-- Left Content -->
            <v-app-bar-nav-icon
              class="d-block d-lg-none me-2"
              @click="isDrawerOpen = !isDrawerOpen"
            >
              <v-img
                width="30"
                contain
                alt=""
                :src="require('@/assets/images/avatars/logo.png')"
              ></v-img>
            </v-app-bar-nav-icon>

            <v-app-bar-title>Reader</v-app-bar-title>
            <v-spacer></v-spacer>

            <!-- Right Content
            <a
              href="https://3ag-edition.com/"
              target="_blank"
              rel="nofollow"
              class="mr-3"
            >
              <v-img
                width="30"
                contain
                alt=""
                :src="require('@/assets/images/avatars/logo.png')"
              ></v-img>
            </a>
            <theme-switcher></theme-switcher> -->
            <app-bar-user-menu
              :user="user"
              :user_id="user.id"
            ></app-bar-user-menu>
          </div>
        </div>
      </v-app-bar>

      <v-main>
        <v-card
          class="overflow-hidden"
        >
          <v-responsive>
            <router-view></router-view>
          </v-responsive>
        </v-card>
        <v-divider class="border-opacity-25 mt-6 mb-6" ></v-divider>
        <v-footer color="transparent"
          class=" text-center d-flex flex-column pb-12"
        >
          <div>
            <v-btn href="https://www.facebook.com/3AgManga"
                   class="mx-4" target="_blank"
                   icon
                   variant="text"
            >
              <v-icon>{{ icons.mdiFacebook }}</v-icon>
            </v-btn>
            <v-btn href="https://www.instagram.com/editorial3ag/"
                   class="mx-4" target="_blank"
                   icon
                   variant="text"
            >
              <v-icon>{{ icons.mdiInstagram }}</v-icon>
            </v-btn>
            <v-btn href="https://www.youtube.com/@edition3ag125/videos"
                   class="mx-4" target="_blank"
                   icon
                   variant="text"
            >
              <v-icon>{{ icons.mdiYoutube }}</v-icon>
            </v-btn>
            <!--        <v-btn
                      class="mx-4"
                      icon
                      variant="text"
                    ><v-icon>{{ icons.mdiGoogle }}</v-icon>

                    </v-btn>-->
          </div>

          <div class="pt-0">
            Plateforme de lecture en ligne des BDs editer par 3ag edition. Espace d'expression de la
            communaute ainsi que
            la creation de contenu pour les auteurs libres.
          </div>

          <v-divider></v-divider>
          Liens utils
          <v-row justify="center" no-gutters>
            <v-btn
              v-for="link in links"
              :key="link.to" target="_blank"
              color="white"
              variant="text"
              class="ma-2"
              :href="link.to"
            >
              {{ link.name }}
            </v-btn>
          </v-row>
          <v-divider></v-divider>

          <div class="mt-6 mb-6">
            {{ new Date().getFullYear() }} — <strong>3ag Edition</strong> <span
            class="d-sm-inline d-none">
            <a
              href="https://business.spacekola.com"
              target="_blank"
              class="text--secondary text-decoration-none"
            >Developed by Spacekola</a>
          </span>
          </div>
        </v-footer>
        <v-dialog
          v-model="dialog"
          fullscreen
          :scrim="false"
          transition="dialog-bottom-transition"
        >
          <v-card>
            <v-toolbar
              dark
              color="primary"
            >
              <v-btn
                icon
                dark
                @click="dialog = false"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>Settings</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-toolbar-items>
                <v-btn
                  variant="text"
                  @click="dialog = false"
                >
                  Save
                </v-btn>
              </v-toolbar-items>
            </v-toolbar>
            container
          </v-card>
        </v-dialog>
      </v-main>
      <bottom-navigation></bottom-navigation>
    </v-layout>
  </v-app>

</template>

<script>
import {
  mdiAccountOutline,
  mdiTwitter,
  mdiGithub,
  mdiGoogle,
  mdiEyeOutline,
  mdiCogOutline,
  mdiGamepadCircleOutline,
  mdiCreation,
  mdiAccountGroupOutline,
  mdiGrid,
  mdiAccessPoint,
  mdiEyeOffOutline,
  mdiFacebook,
  mdiYoutube,
  mdiInstagram,

} from '@mdi/js'
import FlatScreen from '@/layouts/components/FlatScreen'
import BottomNavigation from '@/layouts/components/BottomNavigation'
import {ref} from '@vue/composition-api'
import {Drequest} from '@/plugins/Drequest'
import AppBarUserMenu from "@/layouts/components/AppBarUserMenu.vue";
import VerticalNavMenu from "@/layouts/components/vertical-nav-menu/VerticalNavMenu.vue";
import PWAPrompt from "@/layouts/components/PWAPrompt.vue";

export default {
  components: {PWAPrompt, VerticalNavMenu, AppBarUserMenu, BottomNavigation, FlatScreen},

  props: {
    /* user: {
      type: Object,
      default: null,
    }, */
    user_id: {
      type: Number,
      default: null,
    },
  },
  data: () => ({
    user: {},
    dialog: false,
    isDrawerOpen: false,
    bnav: 'home',
    links: [ //'Accueil',
      {name: 'A propos de 3ag edition', to: "https://3ag-edition.com/about-us"},
      {name: 'Se faire editer', to: "https://3ag-edition.com/fr/how-to-publish"},
      {name: 'Boutique en ligne', to: "https://buyamsellam24.com/fr/shops"},
      //'Se faire editer', 'Nos Points de vente', 'Boutique en ligne', 'Nous contacter'
    ],
    icons: {
      mdiAccountOutline,
      mdiTwitter,
      mdiGithub,
      mdiGoogle,
      mdiFacebook,
      mdiInstagram,
      mdiYoutube,
      mdiEyeOutline,
      mdiCogOutline,
      mdiCreation,
      mdiGrid,
      mdiAccountGroupOutline,
      mdiAccessPoint,
      mdiGamepadCircleOutline,
      mdiEyeOffOutline,
    },
    height: window.innerHeight,
  }),

  mounted() {
    this.$nextTick(() => {
      // window.addEventListener('resize', this.onResize)
    })
    this.user = Drequest.getUser()
    console.log(this.user)
  },

  beforeDestroy() {
    // window.removeEventListener('resize', this.onResize)
  },

  methods: {
    onResize() {
      // this.height = window.innerHeight
    },
  },

}
</script>

<style>
#bottom-navigation {
  position: fixed;
}
</style>
