<template>

  <!--    <metainfo>
        <template v-slot:title="{ content }">{{ content ? `${content} | SITE_NAME` : `SITE_NAME` }}</template>
      </metainfo>-->

        <component
          :is="resolveLayout"
          :user="user"
          :user_id="user.id"
        >
          <router-view></router-view>
        </component>

</template>

<script>
import {computed, ref} from '@vue/composition-api'
import {useRouter} from '@/utils'
//import { useMeta } from 'vue-meta'
import LayoutBlank from '@/layouts/Blank.vue'
import LayoutReader from '@/layouts/Reader.vue'
import LayoutContent from '@/layouts/Content.vue'
import LayoutCommunity from '@/layouts/Community.vue'
import UpgradeToPro from './components/UpgradeToPro.vue'
import {Drequest} from '@/plugins/Drequest'
import FlatScreen from '@/layouts/components/FlatScreen'
import VerticalNavMenu from '@/layouts/components/vertical-nav-menu/VerticalNavMenu.vue'

import {provide} from 'vue'
import BottomNavigation from '@/layouts/components/BottomNavigation.vue'
import ThemeSwitcher from '@/layouts/components/ThemeSwitcher.vue'
import AppBarUserMenu from '@/layouts/components/AppBarUserMenu.vue'
import PWAPrompt from '@/layouts/components/PWAPrompt.vue'
// import firebaseMessaging from './firebase'
// import { getMessaging, onMessage } from 'firebase/messaging'

export default {
  mounted() {
    /* const recaptchaScript = document.createElement("script");
    recaptchaScript.setAttribute(
      "src",
      "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-7691146384467908"
    )
    recaptchaScript.setAttribute("async", true)
    document.head.appendChild(recaptchaScript) */
    console.log('Firebase cloud messaging object', this.$messaging)

  },
  components: {
    PWAPrompt,
    AppBarUserMenu,
    ThemeSwitcher,
    BottomNavigation,
    FlatScreen,
    LayoutBlank,
    LayoutContent,
    LayoutReader,
    LayoutCommunity,
    UpgradeToPro,
    VerticalNavMenu,
  },

  /* sockets: {
    connect: function () {
      console.log('socket connected')
    },
    customEmit: function (data) {
      console.log('this method was fired by the socket server. eg: io.emit("customEmit", data)')
    }
  }, */
  setup() {

    /*  useMeta({
       title: '',
       htmlAttrs: { lang: 'en', amp: true }
     }) */

    const {route} = useRouter()
    const user = ref(Drequest.getUser())
    console.log(user)
    const resolveLayout = computed(() => {
      // Handles initial route
      const uuid = localStorage.getItem('uuid_session')
      if (!uuid) {
        Drequest.init('https://www.uuidgenerator.net/api/version4')
          .get(uuid => {
            console.log(uuid)
            localStorage.setItem('uuid_session', uuid)
          }, {
            dataType: 'text',
          })
      } else {
        console.log(uuid)
      }

      if (route.value.name === null) return null

      if (route.value.meta.layout === 'blank') return 'layout-blank'
      if (route.value.meta.layout === 'reader') return 'layout-blank'
      if (route.value.meta.layout === 'scan') return 'layout-reader'
      if (route.value.meta.layout === 'community') return 'layout-community'

      return 'layout-content'
    })

    const tabs = ref(1)
    const isDrawerOpen = ref(null)
    const dialog = ref(false)

    const bnav = ref(0)
    return {
      isDrawerOpen,
      bnav,
      tabs,
      user,
      resolveLayout,
      dialog,
    }
  },
}
</script>
