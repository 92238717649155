import { render, staticRenderFns } from "./Content.vue?vue&type=template&id=42b23765&scoped=true&"
import script from "./Content.vue?vue&type=script&lang=js&"
export * from "./Content.vue?vue&type=script&lang=js&"
import style0 from "./Content.vue?vue&type=style&index=0&id=42b23765&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "42b23765",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib/components/VApp';
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VAppBarNavIcon } from 'vuetify/lib/components/VAppBar';
import { VCard } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VMain } from 'vuetify/lib/components/VMain';
import { VResponsive } from 'vuetify/lib/components/VResponsive';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VApp,VAppBar,VAppBarNavIcon,VCard,VIcon,VLayout,VMain,VResponsive,VSpacer,VToolbarTitle})
