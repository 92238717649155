import $ from 'jquery'
import axios from 'axios'

export class Drequest {
  static __env = process.env.NODE_ENV === 'production' ? 'https://3ag-edition.com/' : 'http://127.0.0.1/edition3ag/'
  //static __env = process.env.NODE_ENV === 'production' ? 'https://3ag-edition.com/' : 'https://3ag-edition.com/'

  /* static __env = $(document)
    .find('html')
    .data('api') */
  static paymenttoken = 'fSF7e0FE.dcb74c1547604de43bd608725b38f3623114f0c4'

  static __client = $(document)
    .find('html')
    .data('client')

  static __user = null // JSON.parse($(document).find("#data-user").text());
  static __ads = null // JSON.parse($(document).find("#data-user").text());

  baseurl = Drequest.__env

  url = ''

  _param = '' // {iduser:Drequest.__user.id};

  _data = {}

  /* static getuser(){
      return Drequest.api("detail.user?id="+Drequest.__user.id)
  } */
  constructor() {

  }

  static init(url) {
    const r = new Drequest()
    r.baseurl = url
    r.url = ''

    return r
  }

  static api(url) {
    const r = new Drequest()
    r.baseurl = `${Drequest.__env}fr/api/${url}`
    console.log(r.baseurl)

    return r
  }

  static localstorage() {
    if (localStorage.getItem('edition3ag')) {
      try {
        return JSON.parse(localStorage.getItem('edition3ag'))
      } catch (e) {
        localStorage.removeItem('edition3ag')
      }
    }

    return {}
  }

  static localsave(database) {
    localStorage.setItem('edition3ag', JSON.stringify(database))
  }

  param(_param) {
    this._param = _param

    return this
  }

  data(_data) {
    this._data = _data

    return this
  }

  toFormdata(_data) {
    const keys = Object.keys(_data)
    const values = Object.values(_data)

    const fd = new FormData()
    for (let i = 0; i < keys.length; i++) {
      if (typeof values[i] === 'object' && values[i] !== null) {
        fd.append(keys[i], values[i].id)
      } else {
        fd.append(keys[i], values[i])
      }
    }
    this._data = fd

    return this
  }

  toDevupsFormdata(entity, _data, fd) {
    let key = ''
    const keys = Object.keys(_data)
    const values = Object.values(_data)

    if (!fd) {
      fd = new FormData()
    }

    for (let i = 0; i < keys.length; i++) {
      key = `${entity}_form[${keys[i]}]`
      if (typeof values[i] === 'object' && values[i] !== null) {
        fd.append(key, values[i].id)
      } else {
        fd.append(key, values[i])
      }
    }
    this._data = fd

    return this
  }

  post(callback) {
    console.log(this)

    // console.log(this.baseurl + this.url+ $.isEmptyObject(this._param)? "": "?"+ $.param(this._param));
    const keys = Object.keys(this._param)
    const param = (!keys.length) ? '' : `?${$.param(this._param)}`

    return $.ajax({
      url: this.baseurl + this.url + param,
      data: this._data,
      cache: false,
      contentType: false,
      processData: false,
      method: 'POST',
      dataType: 'json',
      success: callback,
      error(e) {
        console.log(e)// responseText
      },
    })
  }

  raw(callback) {
    this._data = JSON.stringify(this._data)

    return this.post(callback)
  }

  get(callback, options = {}) {
    console.log(this._param)

    return $.ajax({
      url: this.baseurl + this.url, // + $.isEmptyObject(this._param)? "": "?"+ $.param(this._param),
      data: this._param,
      method: 'GET',
      dataType: 'json',
      success: callback,
      error(e) {
        console.log(e)// responseText
      },
      ...options,
    })
  }

  static updateUserSession(user, callback = null) {
    Drequest.api(`user.detail?id=${user.id}`)
      .get(response => {
        Drequest.setUser(response.user)
        if (callback) {
          callback()
        }
      })
  }

  static getSubscriptionDetail(sub_id) {
    return Drequest.api(`detail.subscription?id=${sub_id}`)
  }

  static setUser(user) {
    localStorage.setItem('user_id', user.id)
    localStorage.setItem('subscription_id', user.subscription)
    localStorage.setItem('user_session', JSON.stringify(user))
  }

  static logout() {
    localStorage.removeItem('user_id')
    localStorage.removeItem('subscription_id')
    localStorage.removeItem('user_session')
  }

  static getUser() {
    const user_id = localStorage.getItem('user_id')
    if (user_id) {
      return JSON.parse(localStorage.getItem('user_session'))
    }

    return { config: {} }
  }

  static getWalletTransaction(user) {
    return Drequest.api(`lazyloading.wallettransaction?dfilters=on&dsort=id desc&wallet.id:eq=${user.wallet.id}`)
  }

  static getPaymentMethods(user) {
    return Drequest.init(`https://afropay.buyamsellam24.com/app/api/payment.available?country=${user.country.iso}`)
  }

  static getCreditConverted(amount, currency) {
    return Drequest.init('https://afropay.buyamsellam24.com/app/api/currency.convert')
      .toFormdata({
        amount,
        from: currency,
        to: 'XAF',
      })
  }

  static getVerifyPurchase(payment) {
    return Drequest.init(`https://afropay.buyamsellam24.com/app/api/payment.verify?id=${payment.id}`)
  }

  static purchaseWithOMWp(user, payment) {
    return Drequest.init(`https://afropay.buyamsellam24.com/app/api/payment-domain.confirm?token=${Drequest.paymenttoken}`)
      .data({
        payment: {
          amount: payment.amount,
          currency_iso: user.currency_iso,
          purchaseref: payment.reference,
          'payment_method.id': payment.payment_method.id,
        },
        payment_parameters: {},
      })
  }

  static purchaseWithMonetBill(user, payment) {
    return Drequest.init(`https://afropay.buyamsellam24.com/app/api/payment-domain.confirm?token=${Drequest.paymenttoken}`)
      .data({
        payment: {
          amount: payment.amount,
          currency_iso: payment.currency_iso,
          purchaseref: payment.reference,
          'payment_method.id': payment.payment_method.id,
        },
        payment_parameters: {
          phonenumber: payment.phonenumber,
        },
      })
  }

  static purchaseWithPaypal(user, payment) {
    return Drequest.init(`https://afropay.buyamsellam24.com/app/api/payment-domain.confirm?token=${Drequest.paymenttoken}`)
      .data({
        payment: {
          amount: payment.amount,
          currency_iso: payment.currency_iso,
          purchaseref: payment.reference,
          'payment_method.id': payment.payment_method.id,
        },
        payment_parameters: {
          return_url: 'https://afropay.buyamsellam24.com/app/api/paypal-return',
          cancel_url: 'https://afropay.buyamsellam24.com/app/api/paypal-cancel',
        },
      })
  }

  static purchaseWithPaypalCreditCard(user, payment) {
    return Drequest.init(`https://afropay.buyamsellam24.com/app/api/payment-domain.confirm?token=${Drequest.paymenttoken}`)
      .data({
        payment: {
          amount: payment.amount,
          currency_iso: payment.currency_iso,
          purchaseref: payment.reference,
          'payment_method.id': payment.payment_method.id,
        },
        payment_parameters: {
          card: {
            number: payment.card_number,
            expiry: `${payment.card_year}-${payment.card_month}`,
            cvv: payment.card_cvv,
            name: payment.card_owner,
            billing_address: {
              address_line_1: payment.address,
              address_line_2: payment.address,
              admin_area_1: payment.city,
              admin_area_2: payment.city,
              postal_code: payment.cp,
              country_code: user.country.iso,
            },
          },
        },
      })
  }

  static orderWithSubscription(chapter) {
    return Drequest.api(`order.apicreate?token=1&subscribe=${localStorage.getItem('subscription_id')}`)
      .data({
        order: {
          id: '',
          'user.id': localStorage.getItem('user_id'),
        },
        items: [
          {
            'chapter.id': chapter.id,
            amount: chapter.product.price,
            price: chapter.product.price,
            priceofcustomer: chapter.product.price,
            quantity: 1,
            totalamount: chapter.product.price,
          },
        ],
      })
  }

  static orderWithPaymentReference(chapter, paymentref) {
    return Drequest.api('order.apicreate?token=1')
      .data({
        order: {
          id: '',
          paymentref,
          'user.id': localStorage.getItem('user_id'),
        },
        items: [
          {
            'chapter.id': chapter.id,
            amount: chapter.price,
            price: chapter.price,
            priceofcustomer: chapter.price,
            quantity: 1,
            totalamount: chapter.price,
          },
        ],
      })
  }

  static orderWithCredit(chapter) {
    return Drequest.api('order.apicreate?token=1')
      .data({
        order: {
          id: '',
          'user.id': localStorage.getItem('user_id'),
        },
        items: [
          {
            'chapter.id': chapter.id,
            amount: chapter.product.price,
            price: chapter.product.price,
            priceofcustomer: chapter.product.price,
            quantity: 1,
            totalamount: chapter.product.price,
          },
        ],
      })
  }

  static orderWithWallet(orderid) {
    return Drequest.api('order.confirmwallet')
      .param({
        order: orderid,
        subscribe: Drequest.getUser().subscribe,
      })
  }

  static orderSubscribe(subscription) {
    const today = new Date()
    let month = today.getMonth() + 1
    if (month < 10) {
      month = `0${month}`
    }
    let day = today.getDate()
    if (day < 10) {
      day = `0${day}`
    }
    const date = `${today.getFullYear()}-${month}-${day}`

    return Drequest.api('order.subscribe?token=1')
      .data({
        date_from: date,
        order: {
          id: '',
          'user.id': localStorage.getItem('user_id'),
        },
        items: [
          {
            'product.id': subscription.id,
            amount: subscription.price,
            price: subscription.price,
            priceofcustomer: subscription.price,
            quantity: 1,
            totalamount: subscription.price,
          },
        ],
      })
  }

  static orderRefillWallet(paymentref, amount) {
    return Drequest.api(`wallet.refill?user_id=${localStorage.getItem('user_id')}`)
      .toFormdata({
        paymentref,
        amount,
      })
  }

  static createComment(postid, comment) {
    const user = Drequest.getUser()

    return Drequest.api('postcomment.create')
      .data({
        postcomment: {
          'user.id': user.id,
          username: user.username,
          'post.id': postid,
          ...comment,
        },
      })
  }

  static toggleKola(entity, entity_id, notify) {
    let url = 'kola.add'
    if (notify) {
      url = 'kola.remove'
    }

    return Drequest.api(url)
      .toFormdata({
        user_id: localStorage.getItem('user_id'),
        key: entity,
        key_id: entity_id,
      })
  }

  static toggleFollow(entity_id, notify) {
    let url = 'user.follow'
    if (notify) {
      url = 'user.unfollow'
    }

    return Drequest.api(url)
      .toFormdata({
        user_id: entity_id,
        follower_id: localStorage.getItem('user_id'),
      })
  }

  static getSubscription() {
    return Drequest.api(`lazyloading.subscription?dfilters=on&dsort=id desc&user.id:eq=${localStorage.getItem('user_id')}`)
  }

  static onload = false

  static analytic(entity, entity_id, user_id) {

    if (Drequest.onload) {
      return 0
    }

    const data = {
      entity,
      entity_id,
    }
    if (user_id) {
      data.user_id = user_id
    } else {
      data.uuid = localStorage.getItem('uuid_session')
    }

    Drequest.onload = true
    return Drequest.api('analytic.create')
      .data({ analytic: data })
      .raw(response => {
        Drequest.onload = false
        console.log(response)
      })
      .fail(e => console.log(e))
  }

  upload(onprogress, oncomplete) {

    console.log('POST', this.url)

    return axios.post(this.baseurl + this.url, this._data,
      {
        onUploadProgress: function (e) {
          console.log(e)
          onprogress(e.loaded, e.total, e)
          /* if (e.loaded >= e.total) {
            oncomplete(e)
          } */
        }
      })
      .then(function (res) {
        console.log(res)
        oncomplete(res.data)
      })
      .catch(function (e) {
        console.log(e)
        oncomplete({
          success: false,
          error: e,
          detail: 'erreure est survenue'
        })
      })

  }

}
